import jscrypto from 'jscrypto';
import TagManager from 'react-gtm-module';
import { ConsentLevels } from '~/components/organisms/DataCollectionConsent';
import globalConfig from '~/configs/global';
import BookTypes, { BookFormats } from '~/types/app/book';
import { CartItemAccessTypes, VippsOrder } from '~/types/app/shop';
import { CurrentUserTypes, UserTypes } from '~/types/app/user';
import { pendoSnippet } from './pendoSnippet';
const { SHA256 } = jscrypto;

// consentListeners, onGtmConsentChange, window.addGtmConsentListener are related to this tutorial
// https://developers.google.com/tag-platform/tag-manager/templates/consent-apis and the gtm-consent-template
// Array of callbacks to be executed when consent changes
const consentListeners: any = [];

export const onGtmConsentChange = (consent: ConsentLevels) => {
    let gtmConsentObject = {};
    if (consent === 'acceptAll') {
        gtmConsentObject = {
            adConsentGranted: true,
            adUserDataConsentGranted: true,
            adPersonalizationConsentGranted: true,
            analyticsConsentGranted: true,
            functionalityConsentGranted: true,
            personalizationConsentGranted: true,
            securityConsentGranted: true,
        };
    }

    if (consent === 'onlyNecessary') {
        gtmConsentObject = {
            adConsentGranted: false,
            adUserDataConsentGranted: false,
            adPersonalizationConsentGranted: false,
            analyticsConsentGranted: false,
            functionalityConsentGranted: false,
            personalizationConsentGranted: true,
            securityConsentGranted: true,
        };
    }

    consentListeners.forEach((callback: any) => {
        callback(gtmConsentObject);
    });
};

export const initializeGtm = (currentUser: CurrentUserTypes): void => {
    if (globalConfig.publicKeys.GOOGE_TAG_MANAGER_ID && typeof window !== 'undefined') {
        window.addGtmConsentListener = (callback) => {
            consentListeners.push(callback);
        };

        TagManager.initialize({
            gtmId: globalConfig.publicKeys.GOOGE_TAG_MANAGER_ID,
        });

        pushUserToDataLayer(currentUser);
        // Initiate meta pixel after user data is pushed to data layer, so we get it in meta
        initMetaPixel();
    }
};

export const initMetaPixel = (): void => {
    if (TagManager) {
        const event = {
            event: 'initMetaPixel',
        };

        TagManager.dataLayer({
            dataLayer: event,
        });
    }
};

export const enableHubspotTracking = (currentUser: CurrentUserTypes): void => {
    // Reactivate hubspot
    // const _hsq = (window._hsq = window._hsq || []);
    // _hsq.push(['doNotTrack', { track: true }]);

    if (currentUser?.signedIn && !currentUser.user.organisation.isOrganisationUser) {
        const _hsq = (window._hsq = window._hsq || []);
        if (!_hsq.some((el: any) => el[0] === 'identify') && window.USER?.email) {
            _hsq.push(['identify', { email: window.USER.email }]);
        }
    }
};

export const disableHubspotTracking = (): void => {
    // Hubspot
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['doNotTrack']);
};

export const enablePendoTracking = (currentUser: CurrentUserTypes): void => {
    if (globalConfig.publicKeys.PENDO_ID) {
        pendoSnippet(globalConfig.publicKeys.PENDO_ID);

        if (window.pendo) {
            let visitor;
            if (!currentUser.user.organisation.isOrganisationUser) {
                visitor = {
                    id: currentUser.user.id ?? '',
                    email: currentUser.user.loginEmail ?? '',
                    full_name: currentUser.user.firstName ?? '' + currentUser.user.lastName ?? '',
                    login_method: currentUser.user.loginMethod,
                    has_active_subscriptions: currentUser.userHasAPlusSubscription,
                    has_subscription_with_automatic_renewal: currentUser.userHasSubscriptionWithAutomaticRenewal,
                    user_type: currentUser.additionalUserData.userType ?? 'unknown',
                    user_institute: currentUser.additionalUserData.institute?.name ?? 'unknown',
                    user_year_of_graduation: currentUser.additionalUserData.yearOfGraduation ?? 0,
                    user_topics_of_interest: currentUser.additionalUserData.topicsOfInterest ?? [],
                    is_organisation_user: false,
                    has_publisher_access: currentUser.user?.hasPublisherAccess ?? false,
                    publishers_list_access: currentUser.user?.publisher?.id ? [currentUser.user?.publisher?.id] : [],
                };
            } else {
                visitor = {
                    organisation_name: currentUser.user?.organisation.seats?.[0]?.organization?.name ?? '',
                    organisation_id: currentUser.user?.organisation.seats?.[0]?.organization?.id ?? '',
                    is_organisation_user: true,
                };
            }
            window.pendo.initialize({
                visitor: visitor,
            });
        }
    }
};

export const disablePendoTracking = (): void => {
    if (window?.pendo?.stopSendingEvents) {
        window?.pendo?.stopSendingEvents();
    }
    if (window?.pendo?.teardown) {
        window?.pendo?.teardown();
    }
    if (window?.pendo) {
        delete window.pendo;
    }
};

export const pushPurchaseEvent = (order: VippsOrder, user: UserTypes): void => {
    if (order && TagManager) {
        const event = {
            event: 'purchase',
            ecommerce: {
                transaction_id: order.transaction_id ?? '',
                value: order.amount ?? 0,
                tax: order.vat_amount ?? 0,
                currency: order.currency ?? '',
                content_ids: order.isbns ?? [],
                item_ids: order.isbns?.join(',') ?? '',
                user_phone: order.user_phone ?? '',
                user_email: user?.loginEmail ?? '',
                items: order.items?.map((item) => {
                    return {
                        item_id: item.id ?? '',
                        item_name: item.content_id ?? '',
                        affiliation: item.product_owner ?? '',
                        price: item.amount ?? 0,
                    };
                }),
            },
        };

        TagManager.dataLayer({
            dataLayer: event,
        });
    }
};

export const pushAddToCartEvent = (
    user: UserTypes,
    book: BookTypes,
    accessType: CartItemAccessTypes,
    duration?: number,
): void => {
    if (window && book?.ean && TagManager) {
        let value = 0;

        if (accessType === 'BUY') {
            value = book.meta?.grossSalePrice ?? 0;
        }

        if (accessType === 'RENT' && Array.isArray(book.meta.rentalOptions) && duration) {
            value = book.meta.rentalOptions.find((option) => option.durationMonths === duration)?.price ?? 0;
        }

        const event = {
            event: 'addToCart',
            ecommerce: {
                // Below for Meta pixel event
                value,
                content_ids: [book.ean],
                currency: 'NOK',
                contents: [{ id: book.ean, quantity: 1, ean: book.ean }],
                // Below for Snap event
                item_ids: book.ean,
                user_email: user?.loginEmail ?? '',
            },
        };

        TagManager.dataLayer({
            dataLayer: event,
        });
    }
};

export const pushBookViewEvent = (book: BookTypes): void => {
    if (book.ean && TagManager && book.title) {
        const event: Record<string, string | any> = {
            event: 'viewBook',
            ecommerce: {
                content_name: book.title,
                content_ids: [book.ean],
                contents: [{ id: book.ean, quantity: 1, ean: book.ean }],
            },
        };

        TagManager.dataLayer({
            dataLayer: event,
        });
    }
};

export const pushUserToDataLayer = (currentUser: CurrentUserTypes): void => {
    if (currentUser?.signedIn && TagManager) {
        // Formatting requirements come from meta and are listed here:
        // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters
        const phoneNumber = currentUser.user?.phone ? '47' + currentUser.user?.phone : '';
        const email = currentUser.user?.loginEmail ? currentUser.user?.loginEmail.trim().toLowerCase() : '';
        const event = {
            event: 'userLoggedIn',
            user: {
                meta_external_id: currentUser.user.id ?? phoneNumber ?? email ?? '',
                user_email: email,
                user_phone: phoneNumber,
                user_email_hash: SHA256.hash(email).toString(),
                user_phone_hash: SHA256.hash(phoneNumber).toString(),
            },
        };

        TagManager.dataLayer({
            dataLayer: event,
        });
    }
};

export const publishSubscriptionConsumption = ({
    bookFormat,
    length,
    isbn,
    currentUser,
}: {
    bookFormat: BookFormats;
    length: number;
    isbn: string;
    currentUser: CurrentUserTypes;
}) => {
    if (globalConfig.featureFlags.isAnalyticsEnvironment && window.pendo) {
        let eventPayload: Record<string, string | boolean> = {
            bookFormat: bookFormat,
            length: JSON.stringify(length),
            isbn: isbn,
        };

        if (currentUser.user.organisation.isOrganisationUser) {
            eventPayload = {
                ...eventPayload,
                organisation_name: currentUser.user?.organisation.seats?.[0]?.organization?.name ?? '',
                organisation_id: currentUser.user?.organisation.seats?.[0]?.organization?.id ?? '',
                is_organisation_user: currentUser.user?.organisation.isOrganisationUser ?? false,
            };
        }

        window.pendo.track('SubscriptionUsage', eventPayload);
        window.pendo.flushNow(); // makes sure the event is sent immediately
    }
};

export const isConsentCookieIncomplete = (consentObject: any): boolean => {
    return (
        consentObject &&
        [
            'onlyNecessary',
            'consentToAnalytics',
            'consentToAnalyticsTime',
            'adConsentGranted',
            'adUserDataConsentGranted',
            'adPersonalizationConsentGranted',
            'analyticsConsentGranted',
            'functionalityConsentGranted',
            'personalizationConsentGranted',
            'securityConsentGranted',
        ].filter((key) => consentObject[key] === undefined).length > 0
    );
};
